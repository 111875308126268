import React, { useState } from "react";
import { Col, Typography } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import * as classes from "./BrowseProductLineContent.module.scss";
import { CustomButton } from "../../adaptors/CustomButton";
import BrowseCoursesList from "../BrowseCoursesList";
import { isSSR } from "../../../helpers/global";
import { CustomModal } from "../../CustomModal";
import CoursesCategories from "../CoursesCategories";
import { bindActionCreators } from "redux";
import { modalActions } from "../../../utils/redux/actions";

const { Title, Paragraph } = Typography;

const BrowseProductLineContent = (props) => {
  const { title, courses, categoryList, activeSlug } = props;
  const [open, SetOpen] = useState(false);
  const dispatch = useDispatch();

  const { user } = useSelector((state) => {
    return state;
  });

  const { openAuthModal } = bindActionCreators(modalActions, dispatch);

  const getCourseLimit = () => {
    if (!isSSR()) {
      const width = window.innerWidth;
      if (width > 1600) {
        return 8;
      }
      return 6;
    } else {
      return 8;
    }
  };

  return (
    <Col className={classes.coureseSerachResultContent}>
      <Col className={classes.browseCategoriesMobile}>
        <Title className={classes.bannerImageTitle}>{title && title}</Title>
        {open ? (
          <UpOutlined
            onClick={() => {
              SetOpen(false);
            }}
          />
        ) : (
          <DownOutlined
            onClick={() => {
              SetOpen(true);
            }}
          />
        )}
        <CustomModal
          visible={open}
          onCancel={() => {
            SetOpen(false);
          }}
          width="fit-content"
        >
          <CoursesCategories
            categoryList={categoryList}
            activeSlug={activeSlug}
          />
        </CustomModal>
      </Col>
      <Col className={classes.bannerImageContainer}>
        <Col className={classes.bannerImageShade}>
          <Title className={classes.bannerImageTitle}>{title && title}</Title>
          <Paragraph className={classes.bannerImageDescription}>
            Accelerate your career with our expert led {title && title}
          </Paragraph>
          <CustomButton
            customClass={classes.customClass}
            customTextClass={classes.customTextClass}
            variant="primary"
            title="Start For Free"
            onClick={() => {
              const isUserSignedIn = user?.isUserLoggedIn;
              if (!isUserSignedIn) {
                openAuthModal();
              } else {
                window.open("/skill-courses");
              }
            }}
          />
        </Col>
      </Col>
      <Col className={classes.coureseSerachResultContentBottom}>
        <BrowseCoursesList
          title={title}
          courses={courses}
          limit={getCourseLimit()}
        />
      </Col>
    </Col>
  );
};

export default BrowseProductLineContent;
