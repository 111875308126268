// extracted by mini-css-extract-plugin
export var bannerImageContainer = "BrowseProductLineContent-module--bannerImageContainer--98b45";
export var bannerImageDescription = "BrowseProductLineContent-module--bannerImageDescription--b8689";
export var bannerImageShade = "BrowseProductLineContent-module--bannerImageShade--08c30";
export var bannerImageTitle = "BrowseProductLineContent-module--bannerImageTitle--4358a";
export var browseCategoriesMobile = "BrowseProductLineContent-module--browseCategoriesMobile--6ed05";
export var coureseSerachResultContent = "BrowseProductLineContent-module--coureseSerachResultContent--a21c1";
export var coureseSerachResultContentBottom = "BrowseProductLineContent-module--coureseSerachResultContentBottom--1f0fb";
export var customClass = "BrowseProductLineContent-module--customClass--79238";
export var customTextClass = "BrowseProductLineContent-module--customTextClass--b4093";
export var gap = "BrowseProductLineContent-module--gap--64e85";
export var lmsScrollbar = "BrowseProductLineContent-module--lms-scrollbar--71c30";