import React from "react";
import { Col } from "antd";

import * as classes from "../../../styles/browse.module.scss";
import { useBrowseCoursesList } from "../../../components/Browse/hooks/useBrowseCoursesList";
import LayoutWithOutPadding from "../../../components/LayoutWithOutPadding";
import CoursesCategories from "../../../components/Browse/CoursesCategories";
import BrowseProductLineContent from "../../../components/Browse/BrowseProductLineContent";

const ProductLine = (props) => {
  const { pageContext } = props;
  const { productLine } = pageContext;
  const { categoryCourses } = useBrowseCoursesList();

  return (
    <LayoutWithOutPadding>
      <Col className={classes.browsePageContainer}>
        <Col className={classes.browseCategories}>
          <CoursesCategories
            categoryList={categoryCourses?.data}
            activeSlug={productLine?.slug}
          />
        </Col>
        <Col className={classes.browseCourses}>
          <BrowseProductLineContent
            title={productLine?.title}
            courses={productLine?.courses}
            categoryList={categoryCourses?.data}
            activeSlug={productLine?.slug}
          />
        </Col>
      </Col>
    </LayoutWithOutPadding>
  );
};

export default ProductLine;
